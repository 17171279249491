<template>
  <div class="column-center create-root-container">
    <div class="column-center create-content-width" style="background-color: #ffffff; padding: 0px 50px">
      <span class="create-page-title" style="margin-top: 30px">
        参加【艺术联创】如何创作
      </span>
      <span class="create-page-content">
        参加者完成报名&缴费后，会收到主办方或报名机构发的《艺术家“留白”作品图集》。这个图集包括5位画家“留白”作品的8开/A3两种尺寸，并有横、竖版本样图。
      </span>
      <span class="create-page-content">
        决定好自己的作品尺寸，用250g以下的素描纸、水彩纸、水粉纸、白卡纸等到任意数码打印店，在纸上打印画家作品，然后小作者在这张画上“延展想象”，继续创作。将作品交给报名机构或快递给组委会（见“联系我们”中地址）。
      </span>

      <span class="create-page-title create-label" style="margin-top: 30px; align-self: flex-start; text-indent: 36px">
        如何“联创”
      </span>
      <span
        class="create-page-content">为了让大家可以更直观、简易的创作，我们准备了不同尺寸的艺术家作品图，包括A3/8开。同时，提供了多种画面布局样图，报名者可直接从中选取一幅下载、打印。</span>
      <span class="create-page-content" style="align-self: flex-start">当然，也可自行调整图片尺寸和布局，大小为A3或8开即可。</span>
      <span class="create-page-content" style="align-self: flex-start">用纸建议：A3/8开、250克以下。素描纸、水彩纸、水粉纸、白卡纸等。</span>
      <span class="create-page-content" style="align-self: flex-start">打印建议：在数码打印店印制效果最佳。</span>

      <span class="create-page-content" style="align-self: flex-start">以下为主竞单元作品的不同布局<span
          class="create-label">示意</span>：</span>
      <img :src="icons.create_row_center" width="800" />
      <span>艺术家作品在A3纸上横版居中布局</span>

      <img :src="icons.create_row_end" width="800" />
      <span>艺术家作品在A3纸上横版居下布局</span>

      <img :src="icons.create_col_top" width="800" />
      <span>艺术家作品在A3纸上竖版居上布局</span>

      <img :src="icons.create_row_top" width="800" />
      <span>艺术家作品在A3纸上横版居上布局</span>

      <span class="create-page-content" style="align-self: flex-start">以下为创造力单元留白图的不同布局<span
          class="create-label">示意</span>：</span>

      <img :src="icons.create_xmbs_row" width="800" />
      <span>小莫泊桑留白图在A3纸上横版满铺布局</span>

      <img :src="icons.create_xmbs_row_center" width="800" />
      <span>小莫泊桑留白图在A3纸上横版居中布局</span>

      <span class="create-page-title create-label" style="margin-top: 30px; align-self: flex-start; text-indent: 36px">
        说明：
      </span>
      <span class="create-page-content" style="align-self: flex-start"><span
          style="color: #0065b8; font-weight: bold">01</span>
        以上布局图都提供给报名者家长，由小作者自由选择。</span>
      <span class="create-page-content" style="align-self: flex-start"><span
          style="color: #0065b8; font-weight: bold">02</span>
        五位国际艺术家“留白”高清完整作品及小莫泊桑“留白”图集，将在报名完成后，发送到“小创作者”手中，期待大家的“延展想象”。 </span>
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);
  },
};
</script>

<style>
.create-content-width {
  width: 800px;
}
.create-page-title {
  font-family: PingFangSC-Semibold;
  font-size: 36px;
  color: #282828;
  letter-spacing: 0;
  text-align: center;
}
.create-label {
  /* font-family: PingFangSC-Semibold; */
  font-size: 36px;
  color: #0065b8;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  margin-top: 55px;
}
.create-content-container {
  margin-top: 15px;
  width: 305px;
  padding: 15px;
  background: #f83a02;
  border-radius: 5px;
}
.create-content {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
}
.create-download {
  cursor: pointer;
  text-decoration: underline;
}
.create-qa-container {
  margin-top: 30px;
  align-self: flex-start;
}
.create-qa-question {
  font-family: PingFangSC-Semibold;
  font-weight: bold;
  font-size: 24px;
  color: #282828;
  letter-spacing: 0;
}
.create-qa-answer {
  white-space: pre-wrap;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
.create-page-content {
  font-size: 18px;
  text-indent: 36px;
}
</style>